<template>
  <div class="container main col-md-12">
    <div class = "row mb-3">
      <div class ='col-sm'>
         <router-link to="/publications"> Return to Publications</router-link>
      </div>
     </div>
     <template v-if="project.status == 'published'">
    <div class = "row mb-2">
      <div class= "col-md-12">
        <h2 class ="projectTitle"><b>{{project.title}}</b></h2>
      </div>
    </div>
    <div class="row authorList mb-2 ">
      <div class="col-sm mb-2 text-center" v-for='author in project.authors' :key='author.url'>
        <div> <a :href='author.url'> {{author.name}} </a></div>
        <div>{{author.institution}}</div>
      </div>
    </div>
    <div class = "row">
      <div class = "col-12 paper_data text-center mb-1">
        <span class="proceedings">In Proceedings of <a :href="project.venueURL">{{project.venue}}</a></span>
      </div>
    </div>
<div class = "row">
      <div class = "col-12 paper_data text-center mb-3">
        <span v-for='w in project.web' :key='w.link'>
        <router-link v-if="w.text == 'BibTeX'" :to='{name: "bibtex", params: {data: w.link}}' class = "blocklink">
              {{w.text}}
          </router-link>
        <a class = "blocklink" v-else :href='w.link'>{{w.text}}</a>
      </span>

   </div>
    </div>
    <div class = "row">
      <div class = "col-md-12 text-center mb-4">
        <div v-if="project.video" style="padding:56.25% 0 0 0;position:relative;"><iframe :src="project.video" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
        <div v-else><img :src = "project.image" /></div>
      </div>
    </div>
    <div class = "row">
      <div class = "col-md-12 abstract">
       <h3><b>Abstract</b></h3>

        <p>{{project.abstract}}</p>
      </div>
      <div class = "col-md-12 abstract">
        <p><span v-html="project.bodyText"></span></p>
      </div>
    </div>
  </template>
   <template v-else>
    <div class = "row mb-2">
      <div class= "col-md-12">
        Sorry, this publication is not yet public. It will be made available soon.
      </div>
    </div>
  </template>
  </div>
</template>

<script>
import PubsService from '@/services/PubsService'
import AuthorsService from '@/services/AuthorsService'
export default {
  name: 'project',
  data () {
    return {
      airtableProjectResponse: [],
      airtableAuthorResponse: []
    }
  },

  mounted: function () {
    const self = this
    async function getProject () {
      try {
        const response = await PubsService.getProject(self.$route.params.slug)
        const records = response.data.records
        if (records.length > 0) {
          const authors = records[0].fields.author
          self.airtableProjectResponse = records
          if (self.airtableProjectResponse[0].fields.published === true && self.airtableProjectResponse[0].fields.status !== 'confidential') {
            self.airtableAuthorResponse = await AuthorsService.getAuthorList(authors)
            return
          } else {
            self.$router.push('/404')
          }
        }
      } catch (err) {
        console.log(err)
        self.$router.push('/404')
      }
    }
    getProject()
  },

  computed: {
    project () {
      const self = this
      var authorList = []
      for (var i = 0; i < self.airtableAuthorResponse.length; i++) {
        const author = AuthorsService.setupAuthor(self.airtableAuthorResponse[i])
        authorList.push(author)
      }

      if (self.airtableProjectResponse[0]) {
        const sorting = self.airtableProjectResponse[0].fields.author
        const authorResult = AuthorsService.sortAuthorList(authorList, sorting)
        var webList = []

        if (self.airtableProjectResponse[0].fields.pdf) {
          webList.push({
            link: self.airtableProjectResponse[0].fields.pdf[0].url,
            text: 'PDF'
          })
        }
        if (self.airtableProjectResponse[0].fields.doi) {
          webList.push({
            link: self.airtableProjectResponse[0].fields.doi,
            text: 'DOI'
          })
        }
        if (self.airtableProjectResponse[0].fields.talk) {
          webList.push({
            link: self.airtableProjectResponse[0].fields.talk,
            text: 'Talk'
          })
        }
        if (self.airtableProjectResponse[0].fields.bibtex) {
          webList.push({
            link: self.airtableProjectResponse[0].fields.bibtex,
            text: 'BibTeX'
          })
        }
        if (self.airtableProjectResponse[0].fields.website) {
          webList.push({
            link: self.airtableProjectResponse[0].fields.website,
            text: 'website'
          })
        }

        var thisProject = {
          title: self.airtableProjectResponse[0].fields.title,
          snippet: self.airtableProjectResponse[0].fields.snippet,
          images: self.airtableProjectResponse[0].fields.image,
          abstract: self.airtableProjectResponse[0].fields.abstract,
          bodyText: self.airtableProjectResponse[0].fields.bodyText,
          authors: authorResult,
          video: (self.airtableProjectResponse[0].fields.video ? self.airtableProjectResponse[0].fields.video + '?title=0&byline=0&portrait=0' : null),
          image: (self.airtableProjectResponse[0].fields.images ? self.airtableProjectResponse[0].fields.images[0].url : null),
          doi: self.airtableProjectResponse[0].fields.doi,
          venue: self.airtableProjectResponse[0].fields.venue,
          venueURL: self.airtableProjectResponse[0].fields.venueURL,
          pdf: self.airtableProjectResponse[0].fields.pdf[0].url,
          talk: self.airtableProjectResponse[0].fields.talk,
          web: webList,
          status: self.airtableProjectResponse[0].fields.status
        }

        if (self.airtableProjectResponse[0].fields.bestpaper) {
          thisProject.award = 'Best Paper Award'
        } else if (self.airtableProjectResponse[0].fields.honorablemention) {
          thisProject.award = 'Best Paper Honorable Mention'
        } else if (self.airtableProjectResponse[0].fields.bestdemo) {
          thisProject.award = 'Best Demo'
        } else {
          thisProject.award = ''
        }
        return thisProject
      }
      return {}
    }
  }
}
</script>
